@import "variables";

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;
}

.text-l {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 1.6vw;
}

.text-m {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 1.5vw;
}

.text-s {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 0.9vw;
}

.text-mob-l {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 3.6vw;
  letter-spacing: 0.1vw;
}

.text-tab-l {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 2.8vw;
  letter-spacing: 0.1vw;
}

.text-mob-s {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 2.8vw;
  letter-spacing: 0.1vw;
}

.text-tab-s {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 2.2vw;
  letter-spacing: 0.1vw;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-500 {
  font-weight: 500;
}

.white-color {
  color: $white;
}

.light-gray-color {
  color: $light-gray;
}

.dark-blue-color {
  color: $dark-blue;
}

.lime-color {
  color: $lime;
}

.text-underline {
  text-decoration: underline;
}