// If you want to override variables do it here
@import "variables";
@import "fonts";
@import "layout";

// #0BFFF4, #FCFF21, #FF49DB, #1F1F66, #141543
body {
  background-color: #141543;

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #141543;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0BFFF4;
  }
}